<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2022-07-12 09:29:31
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-09-22 14:15:08
 * @FilePath: \cz-operation\src\views\Other.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    title="您不是管理员，请使用管理员账号登录"
    ok-text="重新登录"
    :visible="visible"
  >
    <template slot="footer">
      <a-button @click="reLogin">重新登录</a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: 'Other',
  components: {},
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    reLogin() {
      // sessionStorage.clear()
      // localStorage.clear()
      // const authUrl =
      //   process.env.VUE_APP_AUTH_API +
      //   `/sessions/logout?post_logout_redirect_uri=${process.env.VUE_APP_API_OPN_URL}`
      // console.log(authUrl, 'authurl')
      // window.open(authUrl)
      // this.visible = false
      sessionStorage.clear()
      localStorage.clear()
      const homePage = window.location.href
      const authUrl =
        process.env.VUE_APP_AUTH_API +
        `/sessions/logout?post_logout_redirect_uri=${homePage}`
      console.log(authUrl, 'authurl')
      window.open(authUrl)
    },
  },
  created() {
    console.log(this.$store.state.admin)
    if (this.$store.state.admin) {
      console.log('aaa')
      this.$router.push('/layout')
    }
  },
  // beforeRouterEnter: (to, from, next) => {
  //   // ...
  //   if (to.query) {
  //     next({
  //       path: '/layout',
  //     })
  //   } else {
  //     next()
  //   }
  // },
}
</script>
